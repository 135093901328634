<template>
  <div class="square-container">
    <div class="square-top">
      <user-info />
    </div>
    <div class="square-box">
      <router-link to="home">
        <img v-lazy="logo2"  class="square-logo">
      </router-link>
      <div class="square-tab">
        <div class="tab-pane">
          <div class="pane-left">
            <div class="pane-item" :class="type==1?'active':''" @click="changType(1)">
              <img :src="type==1?groundImgActive:groundImg" alt="">
              <span>广场</span>
            </div>
            <div class="pane-item" :class="type==2?'active':''" @click="changType(2)">
              <img :src="type==2?loginImgActive:loginImg" alt="">
              <span>我的场景</span>
            </div>
          </div>
          <!-- <div class="pane-right" @click="createScene">创建场景</div> -->
        </div>
        <div class="tab-content">
          <div class="content-item tab-square" v-show="type==1">
            <div class="explore-item" v-for="item in list" :key="item.id" @click="joinRoom(item.hub_sid,item.embed_token)">
              <img v-lazy="item.pic" class="explore-bcg" />
              <div class="onLineNum" v-if="item.onLineNum"><i></i> {{item.onLineNum}}人在线</div>
              <h5 class="leftBtm headline">{{ item.title }}</h5>
              <p class="leftBtm txt" :title="item.introduction">{{ item.introduction }}</p>
            </div>
          </div>
          <div class="content-item my-scene" v-show="type==2">
            <div class="explore-item" v-for="item in list" :key="item.id"  @click="joinRoom(item.hub_sid)">
              <!-- <div class="operate-box">
                <div class="three-dot" @click="showOperate(item)">
                  <i></i><i></i><i></i>
                </div>
                <ul class="dot-content" v-show="item.isShow">
                  <li @click="deleteBtn(item)">删除</li>
                  <li @click="editAdministrator(item)">管理员</li>
                </ul>
              </div> -->
              <img v-lazy="item.pic" class="explore-bcg" />
              <div class="onLineNum" v-if="item.onLineNum"><i></i> {{item.onLineNum}}人在线</div>
              <h5 class="leftBtm headline">{{ item.title }}</h5>
              <p class="leftBtm txt" :title="item.introduction">{{ item.introduction }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 管理员弹框 -->
    <el-dialog :visible.sync="showAdminPop" :before-close="closePopUp">
      <div class="administratorPopUp">
        <div class="header-title">
          <div class="txt">休息厅-现有管理员</div>
          <div class="closePopUp" @click="closePopUp">
            <svg t="1650694232493" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2021" width="300" height="300">
              <path d="M872.802928 755.99406 872.864326 755.99406 872.864326 755.624646Z" p-id="2022" fill="#ffffff"></path>
              <path d="M927.846568 511.997953c0-229.315756-186.567139-415.839917-415.838893-415.839917-229.329059 0-415.85322 186.524161-415.85322 415.839917 0 229.300406 186.524161 415.84094 415.85322 415.84094C741.278405 927.838893 927.846568 741.29836 927.846568 511.997953M512.007675 868.171955c-196.375529 0-356.172979-159.827125-356.172979-356.174002 0-196.374506 159.797449-356.157629 356.172979-356.157629 196.34483 0 356.144326 159.783123 356.144326 356.157629C868.152001 708.34483 708.352505 868.171955 512.007675 868.171955" p-id="2023" fill="#ffffff"></path>
              <path d="M682.378947 642.227993 553.797453 513.264806 682.261267 386.229528c11.661597-11.514241 11.749602-30.332842 0.234337-41.995463-11.514241-11.676947-30.362518-11.765975-42.026162-0.222057L511.888971 471.195665 385.223107 344.130711c-11.602246-11.603269-30.393217-11.661597-42.025139-0.059352-11.603269 11.618619-11.603269 30.407544-0.059352 42.011836l126.518508 126.887922L342.137823 639.104863c-11.662621 11.543917-11.780301 30.305213-0.23536 41.96988 5.830799 5.89015 13.429871 8.833179 21.086248 8.833179 7.53972 0 15.136745-2.8847 20.910239-8.569166l127.695311-126.311801L640.293433 684.195827c5.802146 5.8001 13.428847 8.717546 21.056572 8.717546 7.599072 0 15.165398-2.917446 20.968567-8.659217C693.922864 672.681586 693.950494 653.889591 682.378947 642.227993" p-id="2024" fill="#ffffff"></path>
            </svg>
          </div>
        </div>
        <div class="administrator-content">
          <div class="administrator-input">
            <span class="addAdmin" @click="addAdmin">添加管理员</span>
            <span class="url">{{copyText}}</span>
            <img class="copyBtn" src="../assets/images/copyBtn.png" alt="复制" @click="copyBtn(copyText)">
          </div>
          <ul class="administrator-edit">
            <li v-for="item in administratorData" :key="item.id">
              <div class="administrator-box">
                <div class="three-dot" @click="showAdmin(item)">
                  <i></i><i></i><i></i>
                </div>
                <ul class="dot-content" v-show="item.isHidden">
                  <li @click="goUserInfo">个人资料</li>
                  <li @click="deleteAdministrator(item)">删除管理员</li>
                </ul>
              </div>
              <img :src="item.url" alt="管理员" class="administrator-picture">
            </li>
          </ul>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import userInfo from '@/components/userInfo/index.vue'
import config from '@/utils/config'
import { getToken } from '@/utils/auth'
import { getRoomList } from '@/api/api'
export default {
  name: "square",
  components: {
    userInfo
  },
  data() {
    return {
      type: 1,
      logo2: require("../assets/images/logo2.png"),
      copyText: 'www.123.com/UAINIA/ajajs',
      groundImg: require('../assets/images/groundImg.png'),
      groundImgActive: require('../assets/images/groundImgActive.png'),
      loginImg: require('../assets/images/loginImg.png'),
      loginImgActive: require('../assets/images/loginImgActive.png'),
      showAdminPop: false,
      list: [],
      administratorData: [{
          id: 1,
          url: require('../assets/images/administrator.png'),
          isHidden: false
        },
        {
          id: 3,
          url: require('../assets/images/administrator.png'),
          isHidden: false
        },
        {
          id: 2,
          url: require('../assets/images/administrator.png'),
          isHidden: false
        }
      ]
    }
  },
  methods: {
    getList() {
      const params = {
        type: this.type,
        page: this.page,
        paginate: this.paginate
      }
      getRoomList(params).then(res => {
        if (res.code == 200) {
          this.list = res.data.data
        }

      })
    },
    changType(e) {
      console.log(e)
      this.type = e
      this.changeParams({ type: this.type })
    },
    changeParams(obj) {
      this.page = obj.page || 1
      const query = Object.assign({}, this.$route.query, obj)
      this.$router.push({
        path: this.$route.path,
        query: query
      })
      this.getList()
    },
    showOperate(item) {
      this.showAdminPop = !this.showAdminPop
      // item.isShow = !item.isShow
    },
    // 创建场景
    createScene() {
      this.$router.push({ path: "/createScene" });
    },
    joinRoom(sid,embed_token) {
      // console.log(location.host)
      // let url = location.host
      // let url =`https://app.metaio.cc/${sid}`
      // openWindow(url, 'meta', 540, 540)
      this.$router.push({ path: `/metaScene?sid=${sid}`})
      // window.open(`${url}/#/metaScene/${sid}`,'_blank');
      //
      // window.open('https://hubs.mozilla.com/QKDFUTF');
      // window.open(`https://app.metaio.cc/${sid}?token=${getToken()}`);
      // top.location.replace(`https://app.metaio.cc/${sid}`);

    },
    // 删除
    deleteBtn(item) {
      const index = this.exploreData.findIndex(val => val.id === item.id)
      this.exploreData.splice(index, 1)
    },
    // 管理员
    editAdministrator(item) {
      this.showAdminPop = true
      item.isShow = false
    },
    // 删除
    deleteAdministrator(item) {
      const index = this.administratorData.findIndex(val => val.id === item.id)
      this.administratorData.splice(index, 1)
    },
    showAdmin(item) {
      item.isHidden = !item.isHidden
    },
    goUserInfo() {
      this.$router.push({ path: "/user" });
    },
    // 添加管理员
    addAdmin() {
      alert('添加管理员')
    },
    // 复制URL
    copyBtn(txt) {
      this.$copyText(txt).then(() => {
        this.$Toast('复制成功', { duration: 2000 })
      })
    },
    // 关闭管理员弹框
    closePopUp() {
      this.showAdminPop = false
    }
  },
  created() {},
  mounted() {
    this.$nextTick().then(res => {
      const query = this.$route.query
      this.page = query.page ? query.page - 0 : 1
      this.type = query.type ? query.type : 1
      this.getList()
    })
  }
}

</script>
<style scoped lang="scss">
@import '../assets/css/square.scss';
::v-deep .el-dialog{
  margin: 0;
  position: unset;
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding:0;
  }
}
</style>
